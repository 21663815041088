import { GlGroup } from "./Interfaces"

export const glCodes: GlGroup[] = [
    { value: 14, name: 'gl_book' },
    { value: 15, name: 'gl_music' },
    { value: 20, name: 'gl_gift' },
    { value: 21, name: 'gl_toy' },
    { value: 22, name: "gl_game"},
    { value: 23, name: "gl_electronics"},
    { value: 27, name: "gl_video"},
    { value: 44, name: "gl_batteries"},
    { value: 60, name: "gl_home_improvement"},
    { value: 63, name: "gl_video_games"},
    { value: 65, name: "gl_software"},
    { value: 74, name: "gl_dvd"},
    { value: 75, name: "gl_baby_product"},
    { value: 79, name: "gl_kitchen"},
    { value: 86, name: "gl_lawn_and_garden"},
    { value: 107, name: "gl_wireless"},
    { value: 111, name: "gl_ebooks"},
    { value: 114, name: "gl_photo"},
    { value: 121, name: "gl_drugstore"},
    { value: 129, name: "gl_audible"},
    { value: 136, name: "gl_downloadable_software"},
    { value: 147, name: "gl_pc"},
    { value: 153, name: "gl_magazine"},
    { value: 171, name: "gl_paper_catalog"},
    { value: 193, name: "gl_apparel"},
    { value: 194, name: "gl_beauty"},
    { value: 195, name: "gl_food_and_beverage"},
    { value: 196, name: "gl_furniture"},
    { value: 197, name: "gl_jewelry"},
    { value: 198, name: "gl_luggage"},
    { value: 199, name: "gl_pet_products"},
    { value: 200, name: "gl_sports"},
    { value: 201, name: "gl_home"},
    { value: 226, name: "gl_media"},
    { value: 228, name: "gl_gift_card"},
    { value: 229, name: "gl_office_product"},
    { value: 241, name: "gl_watch"},
    { value: 246, name: "gl_loose_stones"},
    { value: 251, name: "gl_gourmet"},
    { value: 261, name: "gl_art_craft_supplies"},
    { value: 263, name: "gl_automotive"},
    { value: 264, name: "gl_art"},
    { value: 265, name: "gl_major_appliances"},
    { value: 267, name: "gl_musical_instruments"},
    { value: 279, name: "gl_gift_certificates"},
    { value: 293, name: "gl_tires"},
    { value: 309, name: "gl_shoes"},
    { value: 318, name: "gl_digital_video_download"},
    { value: 325, name: "gl_grocery"},
    { value: 328, name: "gl_biss"},
    { value: 334, name: "gl_digital_book_service"},
    { value: 340, name: "gl_digital_music_purchase"},
    { value: 349, name: "gl_digital_text"},
    { value: 350, name: "gl_digital_periodicals"},
    { value: 351, name: "gl_digital_ebook_purchase"},
    { value: 353, name: "gl_wireless_accessory"},
    { value: 354, name: "gl_wireless_prepaid_phone"},
    { value: 364, name: "gl_personal_care_appliances"},
    { value: 366, name: "gl_digital_software"},
    { value: 367, name: "gl_digital_video_games"},
    { value: 370, name: "gl_wine"},
    { value: 394, name: "gl_prescription_drugs"},
    { value: 395, name: "gl_prescription_eyewear"},
    { value: 396, name: "gl_consumables_physical_gift_cards"},
    { value: 397, name: "gl_consumables_email_gift_cards"},
    { value: 400, name: "gl_digital_accessories"},
    { value: 414, name: "gl_a_drive"},
    { value: 420, name: "gl_financial_products"},
    { value: 421, name: "gl_camera"},
    { value: 422, name: "gl_mobile_electronics"},
    { value: 424, name: "gl_digital_text_2"},
    { value: 425, name: "gl_digital_accessories_2"},
    { value: 439, name: "gl_protected_collection"},
    { value: 442, name: "gl_coins_collectibles"},
    { value: 446, name: "gl_nonactivated_gift_cards"},
    { value: 450, name: "gl_payment_devices"},
    { value: 451, name: "gl_digital_products_3"},
    { value: 462, name: "gl_prime"},
    { value: 465, name: "gl_digital_devices_4"},
    { value: 467, name: "gl_pantry"},
    { value: 468, name: "gl_outdoors"},
    { value: 469, name: "gl_tools"},
    { value: 470, name: "gl_digital_accessories_4"},
    { value: 485, name: "gl_digital_products_3_accessory"},
    { value: 487, name: "gl_digital_products_6"},
    { value: 493, name: "gl_digital_products_5"},
    { value: 494, name: "gl_digital_products_5_accessory"},
    { value: 500, name: "gl_kindle_unlimited"},
    { value: 504, name: "gl_home_entertainment"},
    { value: 510, name: "gl_luxury_beauty"},
    { value: 517, name: "gl_guild"},
    { value: 540, name: "gl_fresh_perishable"},
    { value: 541, name: "gl_fresh_produce"},
    { value: 542, name: "gl_fresh_prepared"},
    { value: 543, name: "gl_fresh_ambient"},
    { value: 546, name: "gl_digital_products_8_accessory"},
    { value: 547, name: "gl_digital_products_9_accessory"},
    { value: 548, name: "gl_digital_products_10_accessory"},
    { value: 549, name: "gl_digital_products_11_accessory"},
    { value: 550, name: "gl_digital_products_12_accessory"},
    { value: 552, name: "gl_digital_products_14_accessory"},
    { value: 553, name: "gl_digital_products_15_accessory"},
    { value: 556, name: "gl_digital_products_18_accessory"},
    { value: 557, name: "gl_digital_products_19_accessory"},
    { value: 558, name: "gl_digital_products_20_accessory"},
    { value: 559, name: "gl_digital_products_21_accessory"},
    { value: 560, name: "gl_digital_products_22_accessory"},
    { value: 562, name: "gl_digital_products_24_accessory"},
    { value: 563, name: "gl_digital_products_25_accessory"},
    { value: 564, name: "gl_digital_products_26_accessory"},
    { value: 570, name: "gl_digital_products_7"},
    { value: 592, name: "gl_live_pets"},
    { value: 594, name: "gl_value_added_services"},
    { value: 610, name: "gl_amazon_money"},
    { value: 618, name: "gl_fsn_devices"},
    { value: 637, name: "gl_vdo_devices"},
    { value: 641, name: "gl_softlines_private_label"},
    { value: 644, name: "gl_bill_payments"},
    { value: 645, name: "gl_sud_devices"},
    { value: 653, name: "gl_zephyr"},
    { value: 657, name: "gl_amazon_prints"},
    { value: 663, name: "gl_project_diamond"},
    { value: 682, name: "gl_sarek"},
    { value: 685, name: "gl_aws_devices"},
    { value: 689, name: "gl_moments"},
    { value: 709, name: "gl_digital_products_9"},
    { value: 716, name: "gl_crosscountry_gift_card"},
    { value: 721, name: "gl_bond_luxury_beauty"},
    { value: 748, name: "gl_uv_services"},
    { value: 749, name: "gl_uv_products"},
    { value: 750, name: "gl_jadzia"},
    { value: 751, name: "gl_protein"},
    { value: 752, name: "gl_non_inventory_supplies"},
    { value: 769, name: "gl_diagnostics_platform"},
    { value: 773, name: "gl_sleep_tracker"}
]
