export enum Stage {
    BETA = 'beta.space.chaces.amazon.dev',
    PROD = 'space.chaces.amazon.dev'
}

export enum Domain {
    beta = 'https://beta.space.chaces.amazon.dev/',
    prod = 'https://space.chaces.amazon.dev/'
}

export enum Group_Keys {
    ldap = 'custom:ANT_GROUP',
    posix = 'custom:POSIX_GROUP',
    ant = 'custom:ANT_GROUP'
}
