import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Table, { TableRow, TableCell } from '@amzn/meridian/table';
import Button from '@amzn/meridian/button';
import Icon from '@amzn/meridian/icon';
import plusKnockoutTokens from '@amzn/meridian-tokens/base/icon/plus-knockout';
import editTokens from '@amzn/meridian-tokens/base/icon/edit';
import trashTokens from '@amzn/meridian-tokens/base/icon/trash';
import '../../stylesheets/Space.css';


const PickAreas = () => {
    // State Info
    const location = useLocation();
    const whidState = location.state?.data;

    const [pickArea, setPickArea] = useState([])
    useEffect(() => {
        Object.values(whidState).find(( { pick_areas }) => setPickArea(pick_areas))
    }, [whidState])

    return (
        <div>
            <Button className='pickButton' type='primary' size='medium'>
                <Icon tokens={plusKnockoutTokens} /> Add Pick Area
            </Button>
            <Table headerRows={1}>
                <TableRow>
                    <TableCell>Pick Area</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>
                        {pickArea.map(pa => (
                            <li key={pa}>
                                <input type='checkbox' />
                                {pa}
                            </li>
                        ))}
                    </TableCell>
                </TableRow>
            </Table>
            <div className='editContainer'>
                <Button className='editButton' type='primary' size='small'>
                    <Icon tokens={editTokens} /> Edit Pick Areas
                </Button>
                <Button className='editButton' type='primary' size='small'>
                    <Icon tokens={trashTokens} /> Delete Pick Area
                </Button>
            </div>
        </div>   
    )
}

export default PickAreas;