import { BinGroup } from "./Interfaces"

export const binTypes: BinGroup[] = [
    { name: "10_INCH_BIN" },
    { name: "11-KIVA-DEEP" },
    { name: "12-KIVA-DEEP" },
    { name: "14-KIVA-DEEP" },
    { name: "14_INCH_BIN" },
    { name: "16-KIVA-DEEP" },
    { name: "18-KIVA-DEEP" },
    { name: "18-TRANS-SORT" },
    { name: "18.5-KIVA-DEEP" },
    { name: "20-KIVA-DEEP" },
    { name: "20_INCH_BIN" },
    { name: "24-KIVA-DEEP" },
    { name: "24-TRANS-SORT" },
    { name: "24_INCH_BIN" },
    { name: "30-KIVA-DEEP" },
    { name: "34-KIVA-DEEP" },
    { name: "4-PALLET" },
    { name: "4-PALLET-MATTRESS" },
    { name: "4-PALLET-MATTRESS-OP" },
    { name: "4-PALLET-OP" },
    { name: "4-PASS-THROUGH" },
    { name: "4-RAINBOW" },
    { name: "4-RAINBOW-MATTRESS" },
    { name: "4-RAINBOW-MATTRESS-OP" },
    { name: "4-RAINBOW-OP" },
    { name: "4-RAINBOW-TOWER" },
    { name: "48-KIVA-DEEP" },
    { name: "48-KIVA-XL" },
    { name: "6-KIVA-DEEP" },
    { name: "6-PALLET" },
    { name: "6-PALLET-MATTRESS" },
    { name: "6-PALLET-MATTRESS-OP" },
    { name: "6-PALLET-OP" },
    { name: "6-PASS-THROUGH" },
    { name: "6-RAINBOW" },
    { name: "6-RAINBOW-MATTRESS" },
    { name: "6-RAINBOW-MATTRESS-OP" },
    { name: "6-RAINBOW-OP" },
    { name: "78-KIVA-TALL" },
    { name: "8-PALLET" },
    { name: "9-KIVA-DEEP" },
    { name: "ALCOHOLIC-DRINKS" },
    { name: "BAGGED-SHOE" },
    { name: "BARREL" },
    { name: "BAT-BIN" },
    { name: "BIN-STICKERED-TOTE" },
    { name: "BULK-RACK" },
    { name: "BULK-STOCK" },
    { name: "CANTILEVER" },
    { name: "CASE-FLOW" },
    { name: "CLAMPABLE-BULK-STOCK" },
    { name: "DRAWER" },
    { name: "FLAT-APPAREL" },
    { name: "FLAT_APPAREL_DRAWER" },
    { name: "FLOOR-PALLET" },
    { name: "FULL-SHELF" },
    { name: "FURNITURE" },
    { name: "GROCERIES" },
    { name: "HALF-PALLET" },
    { name: "HALF-VERTICAL" },
    { name: "HANGER-ROD" },
    { name: "HOOK" },
    { name: "JEWELRY" },
    { name: "LADDER" },
    { name: "LARGE-APPAREL" },
    { name: "LARGE-SHOES" },
    { name: "LIBRARY" },
    { name: "LIBRARY-DEEP" },
    { name: "LIBRARY-DIRECTED" },
    { name: "LIBRARY_DEEP_DRAWER" },
    { name: "LIBRARY_DRAWER" },
    { name: "MATTRESS" },
    { name: "MEAT" },
    { name: "MEDICAL" },
    { name: "MEDIUM-SHOES" },
    { name: "NON-CLAMPABLE-BULK-STOCK" },
    { name: "PALLET-DOUBLE" },
    { name: "PALLET-SINGLE" },
    { name: "PALLET_RACK" },
    { name: "PASS-THROUGH" },
    { name: "PASS-THROUGH-BULKY" },
    { name: "PRODUCE" },
    { name: "QUARTER-PALLET" },
    { name: "RAINBOW" },
    { name: "RANDOM-OTHER" },
    { name: "REACH_IN" },
    { name: "RIVET-SHELVING" },
    { name: "RUG" },
    { name: "SHOES" },
    { name: "SMALL-APPAREL" },
    { name: "SMALL-SHOES" },
    { name: "STEEL-SHELVING" },
    { name: "TASRS-SLOT" },
    { name: "TIER-RACK" },
    { name: "TOTE-BIN" },
    { name: "VERTICAL" },
    { name: "VNA-RACKING" },
    { name: "WET_PRODUCE" }
]