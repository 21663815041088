import React, { FC } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LandingPage from './pages/LandingPage';
import Configurations from './pages/Configurations';
import Scans from './pages/Scans';
import Queues from './pages/Queues';
import Compliance from './pages/Compliance';
import WarehouseHome from './pages/WarehouseHome';
import { AuthWrapper } from './auth/AuthContext';
import AppLayout from '@amzn/meridian/app-layout';
import AppHeader from './components/layout/AppHeader';
import AppFooter from './components/layout/AppFooter';
import { ToastProvider } from './components/responses/Toasts';
import Theme from '@amzn/meridian/theme';
import './App.css';

const Space: FC = ({ children }) => {

    return (
        <AuthWrapper>
                <ToastProvider>
                    <Theme
                        tokens={{
                            themebackgroundPrimaryDefault: "#808080"
                        }}
                    >
                        <Router>
                            <AppLayout
                                headerComponent={AppHeader}
                                footerComponent={AppFooter}
                            >
                                <AppHeader />
                                <AppFooter />
                                <Routes>
                                    <Route path='/' element={<LandingPage />} />
                                    <Route path='/:whid' element={<WarehouseHome />}/>
                                    <Route path='/:whid/configurations' element={<Configurations/>} />
                                    <Route path='/:whid/queues' element={<Queues/>} />
                                    <Route path='/:whid/:queuename' element={<Scans/>} />
                                    <Route path='/:whid/compliance' element={<Compliance/>} />
                                </Routes>
                            </AppLayout>
                        </Router>
                    </Theme>
                </ToastProvider>
        </AuthWrapper>
    );
}

export default Space;
