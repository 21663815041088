import React from 'react';
import { Link } from 'react-router-dom';
import Theme from '@amzn/meridian/theme';
import Card from '@amzn/meridian/card';
import Heading from '@amzn/meridian/heading';
import Button from '@amzn/meridian/button';
import Column from '@amzn/meridian/column';
import Divider from '@amzn/meridian/divider';
import Image from '@amzn/meridian/image';
import { useLocation, useParams } from 'react-router';
import SpaceText from '../assets/SpaceWhiteText.png';
import orangeDarkTokens from '@amzn/meridian-tokens/theme/orange-dark';

const WarehouseHome = () => {
    const location = useLocation();
    const { whid } = useParams();

    return (
        <div style={{display: 'flex', justifyContent: 'center'}}>
            <Card width={400} spacingInset='none'>
                <Image
                    src={SpaceText}
                    size="auto 100%"
                    viewportAspectRatio={3 / 2}
                    viewportBackgroundColor='#252829'
                    viewportSpacingInset={70}
                />
                <Column spacingInset='400 500' spacing='300'>
                    <Heading level={2} type='h500'>
                        {`Warehouse: ${whid}`}
                    </Heading>
                </Column>
                <Divider />
                <Theme tokens={orangeDarkTokens}>
                    <Column spacingInset='400 500'>
                        <Button type='primary'>
                            <Link
                                style={{textDecoration: 'none', color: '#232F3E'}}
                                to={`/${whid}/configurations`}
                                state={{ data: location.state}}
                            >
                                Configurations
                            </Link>
                        </Button>
                        <Button type='primary'>
                            <Link
                                style={{textDecoration: 'none', color: '#232F3E'}}
                                to={`/${whid}/queues`}
                                state={{ data: location.state }}
                            >
                                Queues
                            </Link>
                        </Button>
                        <Button type='primary'>
                            <Link
                                style={{textDecoration: 'none', color: '#232F3E'}}
                                to={`/${whid}/compliance`}
                                state={{ data: location.state }}
                            >
                                Compliance
                            </Link>
                        </Button>
                    </Column>
                </Theme>
            </Card>
        </div>
    )
}

export default WarehouseHome;
