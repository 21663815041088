import React, { useState, useCallback, useEffect } from 'react';
import Button from '@amzn/meridian/button';
import Icon from '@amzn/meridian/icon';
import Select, { SelectOption } from '@amzn/meridian/select';
import Table, { TableRow, TableCell } from '@amzn/meridian/table';
import plusKnockoutTokens from '@amzn/meridian-tokens/base/icon/plus-knockout';
import escapeRegExp from 'lodash/escapeRegExp';
import { glCodes } from '../../common/GlCodes';
import { binTypes } from '../../common/BinTypes';
import { useLocation } from 'react-router-dom';
import '../../stylesheets/Space.css'


const GlPaths = () => {
    const glButton = useCallback(() => console.log('Add GlCode'), [])
    const [glPath, setGlPath] = useState({})

    // State info
    const location = useLocation();
    const whidState = location.state?.data;
    useEffect(() => {
        Object.values(whidState).find(({ gl_paths }) => setGlPath(gl_paths))
    }, [whidState])

    // Size
    const [sizes, setSizes] = useState()

    // Gl Codes
    const [currentGl, setCurrentGl] = useState()
    const [glCode, setGlCode] = useState([])
    const [searchGlQuery, setSearchGlQuery] = useState()
    useEffect(() => {
        setGlCode(glCodes)
    }, [])
    const searchGlRegExp = new RegExp(escapeRegExp(searchGlQuery), 'i')
    const matchedGlCode = glCode.filter(
        option => !searchGlQuery || searchGlRegExp.test(option.name)
    )

    // Bin Types
    const [currentBinType, setCurrentBinType] = useState()
    const [binType, setBinType] = useState([])
    const [searchBinType, setSearchBinType] = useState()
    useEffect(() => {
        setBinType(binTypes)
    }, [])
    const searchBinRegExp = new RegExp(escapeRegExp(searchBinType), 'i')
    const matchedBinType = binType.filter(
        option => !searchBinType || searchBinRegExp.test(option.name)
    )

    return (
        <div>
            <div className='selectGl'>
                <Select
                    value={currentGl}
                    onChange={setCurrentGl}
                    searchQuery={searchGlQuery}
                    onSearch={setSearchGlQuery}
                    placeholder='Select GL Codes...'
                    width={200}
                    size='medium'
                >
                    {matchedGlCode.map(option => (
                        <SelectOption 
                            label={`${option.value} (${option.name})`}
                            value={option.name}
                            key={option.value}
                        />
                    ))}
                </Select>
                <Select
                    value={sizes}
                    onChange={setSizes}
                    placeholder='Select Size...'
                    width={200}
                    size='medium'
                >
                    <SelectOption value='small' label='Small' />
                    <SelectOption value='medium' label='Medium' />
                    <SelectOption value='Large' label='Large' />
                </Select>
                <Select
                    value={currentBinType}
                    onChange={setCurrentBinType}
                    searchQuery={searchBinType}
                    onSearch={setSearchBinType}
                    placeholder='Select Bin Type...'
                    width={200}
                    size='medium'
                >
                    {matchedBinType.map(option => (
                        <SelectOption 
                            label={`${option.name}`}
                            value={option.name}
                            key={option.name}
                        />
                    ))}
                </Select>
            </div>
            <div className='glButton'>
                <Button type='primary' size='small' onClick={glButton}>
                    <Icon tokens={plusKnockoutTokens} /> Add GL Path
                </Button>
            </div>
            <Table headerRows={1}>
                <TableRow>
                    <TableCell alignmentHorizontal={'center'}>GL Code</TableCell>
                    <TableCell alignmentHorizontal={'center'}>Size</TableCell>
                    <TableCell alignmentHorizontal={'center'}>Bin Type</TableCell>
                </TableRow>
                {Object.entries(glPath).map(([id, label]) => (
                    <TableRow key={id}>
                        <TableCell alignmentHorizontal={'center'}>{id}</TableCell>
                        <TableCell alignmentHorizontal={'center'}>{Object.keys(label).join(", ")}</TableCell>
                        <TableCell alignmentHorizontal={'center'}>{Object.values(label).join(", ")}</TableCell>
                    </TableRow>
                ))}
            </Table>
        </div>
    )
}

export default GlPaths;
