import React, { FC } from 'react';
import Masthead, { MastheadTitle } from '@amzn/meridian/masthead';
import Thumbnail from '@amzn/meridian/thumbnail';
import Row from '@amzn/meridian/row';
import Button from '@amzn/meridian/button';
import astroo from '../../assets/astroo.png';
import Theme from '@amzn/meridian/theme';
import orangeDarkTokens from '@amzn/meridian-tokens/theme/orange-dark';

const AppFooter: FC = () => {
    return (
        <Theme tokens={orangeDarkTokens}>
            <Masthead>
                <MastheadTitle>
                    <Row alignmentHorizontal='end'>
                        <Thumbnail size='small' type='square' source={astroo} />
                        <Button type='primary' size='small' href='https://w.amazon.com/bin/view/CHACES' target='_blank'>
                            Built by CHACES
                        </Button>
                    </Row>
                </MastheadTitle>
            </Masthead>
        </Theme>
    )
}

export default AppFooter;
