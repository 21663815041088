import React from "react";
import { useNavigate } from "react-router-dom";
import Icon from '@amzn/meridian/icon';
import arrowLeftLargeTokens from '@amzn/meridian-tokens/base/icon/arrow-left-large';

const BackButton = () => {
    const navigateBack = useNavigate();
    
    return (
        <div>
            <button style={{padding: '5px'}} onClick={() => navigateBack(-1)}>
                <Icon tokens={arrowLeftLargeTokens} />
            </button>
        </div>
    );
}

export default BackButton;
