import React, { FC } from "react"
import { useNavigate } from "react-router";
import Masthead, {
    MastheadTitle,
} from "@amzn/meridian/masthead"
import Thumbnail from "@amzn/meridian/thumbnail"
import Text from "@amzn/meridian/text"
import Row from "@amzn/meridian/row"
import Heading from "@amzn/meridian/heading"
import Tooltip from "@amzn/meridian/tooltip"
import Button from "@amzn/meridian/button"
import Icon from "@amzn/meridian/icon"
import mailTokens from "@amzn/meridian-tokens/base/icon/mail"
import astroo from '../../assets/astroo.png';
import Theme from "@amzn/meridian/theme"
import orangeDarkTokens from "@amzn/meridian-tokens/theme/orange-dark"
import { useAuth } from "../../auth/AuthContext";

const AppHeader: FC = () => {
    const astroStyle = {
        display: 'block',
        height: 36
    };

    const navigate = useNavigate();
    const userDetails = useAuth()

    return (
        <Theme tokens={orangeDarkTokens}>
            <Masthead>
                <MastheadTitle onClick={() => navigate('/')}>
                    <img src={astroo} alt='astro' style={astroStyle}></img>
                </MastheadTitle>
                <Row spacing="medium">
                    <Row spacing="small">
                        <Text>Welcome <br/><b>{userDetails.UserId}</b></Text>
                        <Thumbnail size="small" source={`https://internal-cdn.amazon.com/badgephotos.amazon.com/?uid=${userDetails.UserId}`} />
                    </Row>
                    <Row spacing="small">
                        <Tooltip title="Contact Us" position="bottom" maxWidth={300}>
                            <Button type="secondary" href="https://w.amazon.com/bin/view/CHACES/relo/IssueSIM/" target="_blank">
                                <Icon tokens={mailTokens} />
                            </Button>
                        </Tooltip>
                    </Row>
                </Row>
                <Heading level={1} type='b500'>Space</Heading>
            </Masthead>
        </Theme>
    )
}

export default AppHeader
