import React, { useState } from "react"
import { useParams } from 'react-router-dom';
import Theme from '@amzn/meridian/theme';
import Text from '@amzn/meridian/text';
import Tooltip from '@amzn/meridian/tooltip';
import Box from '@amzn/meridian/box';
import Row from '@amzn/meridian/row';
import ButtonGroup, { ButtonOption } from "@amzn/meridian/button-group"
import orangeDarkTokens from '@amzn/meridian-tokens/theme/orange-dark';
import '../stylesheets/Space.css';
import BackButton from '../components/layout/BackButton';
import Sizes from '../components/fcConfigs/sizes';
import GlPaths from "../components/fcConfigs/glPaths";
import PickAreas from '../components/fcConfigs/pickAreas';
import PriorityOrder from '../components/fcConfigs/priorityOrder';
import TargetUtilization from '../components/fcConfigs/targetUtilization';

const Configurations = () => {
    const [currentTab, setCurrentTab] = useState()

    // State info
    const { whid } = useParams();
    
    const landingBackground = {
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        width: '100%',
        height: '100%',
    }

    return (
        <Theme tokens={orangeDarkTokens}>
            <div className="mainContainer" style={landingBackground}>
                <BackButton />
                <Text type="d200" className="textColor" alignment="center">{`${whid} Configurations`}</Text>
                <div className="toggleConfig">
                    <div className="configSelect">
                        <ButtonGroup value={currentTab} onChange={setCurrentTab}
                            type="fill"
                            size="medium"
                        >
                            <Tooltip position="top" title="Customize GL Paths" id="toolTip">
                                <ButtonOption value="gl">GL Paths</ButtonOption>
                            </Tooltip>
                            <Tooltip position="top" title="Configure Pick Areas" id="toolTip">
                                <ButtonOption value="pickAreas">Pick Areas</ButtonOption>
                            </Tooltip>                        
                            <Tooltip position="top" title="Arrange Priority Order" id="toolTip">
                                <ButtonOption value="priorityOrder">Priority Order</ButtonOption>
                            </Tooltip>                        
                            <Tooltip position="top" title="Configure Custom Sizes" id="toolTip">
                                <ButtonOption value="sizes">Sizes</ButtonOption>
                            </Tooltip>                        
                            <Tooltip position="top" title="Set your Target Utilization" id="toolTip">
                                <ButtonOption value="targetUtilization">Target Utilization</ButtonOption>
                            </Tooltip>
                        </ButtonGroup>
                    </div>
                </div>
                <Row className='configBody'>
                    <Box
                        className='glBox'
                        type={'fill'}
                        width={'80%'}
                        minHeight={'400px'}
                        spacingInset={'medium'}
                    >
                        {currentTab === 'gl' ? (
                            <GlPaths></GlPaths>
                        ):
                        currentTab === 'pickAreas' ? (
                            <PickAreas></PickAreas>
                        ):
                        currentTab === 'priorityOrder' ? (
                            <PriorityOrder></PriorityOrder>
                        ):
                        currentTab === 'sizes' ? (
                            <Sizes></Sizes>
                        ):
                        currentTab === 'targetUtilization' && (
                            <TargetUtilization></TargetUtilization>
                        )
                        }
                    </Box>
                </Row>
            </div>
        </Theme>
    )
}

export default Configurations;
