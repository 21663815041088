export interface CognitoConfig {
    // identityPoolId: string;
    region: string;
    userPoolId: string;
    userPoolWebClientId: string;
    oauth: {
        domain: string;
        redirectSignIn: string;
        redirectSignOut: string;
        scope: string[];
        responseType: string;
    }
}

export enum LoadingStatus {
    NotInitiated = 'Not Initiated',
    Loading = 'Loading',
    Completed = 'Completed',
    Failed = 'Failed'
}

export interface SpaceUserInterface {
    userId: string;
    ldapGroups: string[];
    posixGroups: string[];
    antGroups: string[];
    isAuthorized: boolean
}

export type SpaceGroups = {
    signInUserSession: {
        idToken: {
            payload: {
                'custom:ANT_GROUP'?: string[]
                'custom:LDAP_GROUP'?: string[]
                'custom:POSIX_GROUP'?: string[]
            }
        }
    }
}

export interface SpaceContext {
    UserId: string;
    AntGroup: string[];
    error: string;
    idToken: string;
    userLoadingStatus: LoadingStatus
}

export interface ToteScans {
    printer_ip: string;
    warehouse_id: string;
    queue_name: string;
    badge_id: string;
    totes: string[];
}

export interface AllCompliance {
    warehouse_id: string;
    compliance_id: string;
    login: string;
    tote: string;
    destination: string;
    aisle: string;
    status: string;
}

export interface AllFcConfiguration {
    update_by_etl: boolean;
    warehouse_id: string;
    priority_order: { [key: string]: string};
    pick_areas: string[];
    gl_paths: { [key: string]: GlPath}
    target_utilization: string;
    sizes: Sizes;
}

export interface AllQueueConfiguration {
    warehouse_id: string;
    floor: string;
    pick_areas: string[];
    queue_name: string;
}

export interface SetGlPaths {
    gl_paths: {
        gl_code: Sizes
    }
}

export interface GlPath {
    small?: string;
    large?: string;
    medium?: string;
}

export interface Sizes {
    small: string;
    medium: string;
    large: string;
}

export interface DestinationScan {
    [key: string]: {
        bin_type: string;
        totes: string[];
    }
}

export interface GlGroup {
    value: number;
    name: string;
}

export interface BinGroup {
    name: string;
}
