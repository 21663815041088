import axios from 'axios';

export function axiosGet(url: string, params: object) {
    return new Promise((resolve, reject) => {
        axios.get(`/api/${url}`, { withCredentials: true, params }).then(res => {
            resolve(res.data);
        }).catch(err => {
            reject(err);
        })
    })
}

export function axiosPost(url: string, params: object) {
    return new Promise((resolve, reject) => {
        axios.post(`/api/${url}`, { params }, { withCredentials: true }).then(res => {
            resolve(res.data);
        }).catch(err => {
            reject(err);
        })
    })
}
