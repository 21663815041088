import React, { useContext, createContext, useEffect, useState } from 'react';
import { Auth } from '@aws-amplify/auth';
import { getCognitoConfig } from './AuthConfigs';
import { isEmpty } from '@aws-amplify/core';
import { LoadingSpinner } from '../components/responses/Spinner';
import { CognitoUserSession } from 'amazon-cognito-identity-js';
import { Group_Keys } from '../common/Constants';
import { SpaceContext, LoadingStatus } from '../common/Interfaces';

const cognitoConfig = getCognitoConfig();
const auth = {
    ...cognitoConfig    
};

Auth.configure({ Auth: auth });

const initialData: SpaceContext = { userLoadingStatus: LoadingStatus.Loading } as SpaceContext;
const SpaceContextDetails = createContext(initialData)
export const useAuth = () => useContext(SpaceContextDetails);

export const AuthWrapper = ({ children }) => {
    const userData: SpaceContext = useAuthWrapper();
    if (userData?.userLoadingStatus === LoadingStatus.Loading) {
        return <LoadingSpinner />;
    } else {
        return <SpaceContextDetails.Provider value={userData}>{children}</SpaceContextDetails.Provider>
    }
};

export const useAuthWrapper = () => {
    const [userData, setUserData] = useState<SpaceContext>({
        userLoadingStatus: LoadingStatus.Loading,
    } as SpaceContext);

    useEffect(() => {
        Auth.currentSession()
            .then((session: CognitoUserSession) => {
                let antGroup = session.getIdToken().payload[Group_Keys.ant];
                let UserAntGroup: string[] = isEmpty(antGroup) ? [] : antGroup.split(',');
                setUserData({
                    UserId: session.getIdToken().payload['identities'][0].userId,
                    AntGroup: UserAntGroup,
                    error: '',
                    idToken: session.getIdToken().getJwtToken(),
                    userLoadingStatus: LoadingStatus.Completed
                });
            })
            .catch(() => {
                Auth.federatedSignIn({ customProvider: 'AmazonFederate' });
            });
            return () => {
                Auth.signOut();
            };
    }, []);
    return userData
}
