import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import Theme from '@amzn/meridian/theme';
import Box from '@amzn/meridian/box';
import Text from '@amzn/meridian/text';
import Row from '@amzn/meridian/row';
import Column from '@amzn/meridian/column';
import Table, { TableRow, TableCell } from '@amzn/meridian/table';
import Loader from '@amzn/meridian/loader';
import Button from '@amzn/meridian/button';
import Icon from '@amzn/meridian/icon';
import SideMenu, { SideMenuLink } from '@amzn/meridian/side-menu';
import axios from 'axios';
import { useAuth } from '../auth/AuthContext';
import { axiosGet } from '../utils/axiosClient';
import orangeDarkTokens from '@amzn/meridian-tokens/theme/orange-dark';
import plusKnockoutTokens from '@amzn/meridian-tokens/base/icon/plus-knockout';
import editTokens from '@amzn/meridian-tokens/base/icon/edit';
import trashTokens from '@amzn/meridian-tokens/base/icon/trash';
import arrowRightLargeTokens from '@amzn/meridian-tokens/base/icon/arrow-right-large'
import BackButton from '../components/layout/BackButton';
import '../stylesheets/Space.css';

const Queues = () => {
    // State info
    const { whid } = useParams();

    const [isLoading, setIsLoading] = useState(false);
    const [hash, setHash] = useState()

    const [queue, setQueue] = useState([]);
    axios.defaults.headers.common['Authorization'] = useAuth().idToken;
    useEffect(() => {
        setIsLoading(true)
        axiosGet('get_all_queue_configuration', {warehouse_id: whid})
            .then((queues: any) => {
                setQueue(queues)
                setIsLoading(false)
            }).catch(err => {
                console.error(err)
            })
    }, [whid])
    console.log(queue)

    const landingBackground = {
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        width: '100%',
        height: '100%'
    }

    return (
        <Theme tokens={orangeDarkTokens}>
            <div style={landingBackground}>
                <BackButton />
                <Text type='d200' className='textColor' alignment='center'>{`${whid} Queues`}</Text>
                <Button className='pickButton' type='primary' size='medium'>
                    <Icon tokens={plusKnockoutTokens} /> Add a Queue
                </Button>
                <Row>
                    <SideMenu>
                        {isLoading ? (
                            <Column alignmentHorizontal='center' spacingInset='medium none'>
                                <Loader type='circular' size='medium' />
                            </Column>
                        ) : (
                            queue.map((k,v) => (
                                <SideMenuLink key={v} href={k.queue_name} selected={hash === k.queue_name} onClick={setHash}>
                                    {k.queue_name} | Floor {k.floor}
                                </SideMenuLink>
                            ))
                        )}
                    </SideMenu>
                    {queue.map((queues, index) => 
                        hash === queues.queue_name && (
                            <Box
                                type={'fill'}
                                width={'80%'}
                                minHeight={'400px'}
                                spacingInset={'medium'}
                                key={index}
                            >
                                <Table headerRows={1}>
                                    <TableRow>
                                        <TableCell alignmentHorizontal={'center'}>Queue Name</TableCell>
                                        <TableCell alignmentHorizontal={'center'}>Floor</TableCell>
                                        <TableCell alignmentHorizontal={'center'}>Pick Areas</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell alignmentHorizontal={'center'}>{hash}</TableCell>
                                        <TableCell alignmentHorizontal={'center'}>{queues.floor}</TableCell>
                                        <TableCell alignmentHorizontal={'center'}>
                                            {queues.pick_areas.map(pickArea => (
                                                <li key={pickArea}>{pickArea}</li>
                                            ))}
                                        </TableCell>
                                    </TableRow>
                                </Table>
                                <div className='editContainer'>
                                    <Button className='editButton' type='primary' size='small'>
                                        <Icon tokens={editTokens} /> Edit Queue
                                    </Button>
                                    <Button className='editButton' type='primary' size='small'>
                                        <Icon tokens={trashTokens} /> Delete Queue
                                    </Button>
                                    <Button className='editButton' type='primary' size='small'>
                                        <Link
                                            style={{textDecoration: 'none', color: '#232F3E'}}
                                            to={`/${whid}/${queues.queue_name}`}
                                            state={{ data: queue }}
                                        >
                                            <Icon tokens={arrowRightLargeTokens} /> Use this Queue
                                        </Link>
                                </Button>
                                </div>
                            </Box>
                        )
                    )}
                </Row>
            </div>
        </Theme>   
    )
}

export default Queues;