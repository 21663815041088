import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import mockServer from './mock_server/mockServer';
import "@amzn/meridian-tokens/base/font/amazon-ember.css"
import Space from './Space';


if (process.env.NODE_ENV === "development") {
    mockServer();
}

ReactDOM.render(
        <Space />, 
    document.getElementById('root')
);
