import React, { useState, useEffect } from 'react';
import Button from '@amzn/meridian/button';
import Icon from '@amzn/meridian/icon';
import plusKnockoutTokens from '@amzn/meridian-tokens/base/icon/plus-knockout';
import { useLocation } from 'react-router-dom';

const Sizes = () => {
    const [size, setSize] = useState({})

    // State Info
    const location = useLocation();
    const whidState = location.state?.data;
    useEffect(() => {
        Object.values(whidState).find(({ sizes }) => setSize(sizes))
    }, [whidState])

    return (
        <div>
            <Button type='primary' size='small'>
                <Icon tokens={plusKnockoutTokens} /> Edit Size Attribute
            </Button>
            <i style={{color: '#eaeaea', margin: '5px'}}>Input the minimum item volume for size attribute</i>

            <table style={{
                display: 'flex',
                marginTop: '1%',
                width: '150px',
                height: '300px'
            }}
            >
                <thead style={{
                    display: 'flex',
                    flexDirection: 'column',
                    color: '#eaeaea'
                }}
                >
                    {Object.entries(size).map(([id, label]) => (
                        <tr key={id}>
                            <td>{id}: {label}</td>
                        </tr>
                    ))}
                </thead>
            </table>
        </div>
    )
}

export default Sizes;