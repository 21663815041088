import React, { useState, useCallback, useEffect } from "react";
import { useParams, useLocation } from 'react-router-dom';
import Text from "@amzn/meridian/text";
import Box from '@amzn/meridian/box';
import Button from '@amzn/meridian/button';
import Divider from '@amzn/meridian/divider';
import Card from '@amzn/meridian/card';
import Row from '@amzn/meridian/row';
import Theme from '@amzn/meridian/theme';
import Column from '@amzn/meridian/column';
import Loader from '@amzn/meridian/loader';
import orangeDarkTokens from '@amzn/meridian-tokens/theme/orange-dark';
import '../stylesheets/Space.css';
import BackButton from '../components/layout/BackButton';
import { axiosPost } from "../utils/axiosClient";
import { useAuth } from '../auth/AuthContext';
import axios from 'axios';
import toteimg from '../assets/Tote.png';
import { useToaster, TOAST_TIMEOUT_5_SEC } from '../components/responses/Toasts';
import { DestinationScan } from "../common/Interfaces";

const Scans = () => {
    const [scan, setScan] = useState<DestinationScan>({})
    const [isLoading, setIsLoading] = useState(false)

    const [counter, setCounter] = useState(null);

    // Init Toast
    const toast = useToaster();

    // State Info
    const { whid } = useParams();
    const location = useLocation();
    console.log(location)
    const whidState = location.state?.data;
    console.log(whidState)

    const queueName = location.pathname.replace(`/${whid}/`, '')

    // Adding Totes
    const [tote, setTote] = useState('')
    const [totes, setTotes] = useState([])
    const toteChange = (event) => {
        setTote(event.target.value.trim())
    }

    const toteClick = useCallback(() => {
        if (!totes.includes(tote) && tote !== ''){
            setTotes(totes => totes.concat(tote))
            setTote('')
        } else if (totes.includes(tote)) {
            toast({
                message: 'Duplicate Tote ID',
                type: 'error',
                timeout: TOAST_TIMEOUT_5_SEC
            })
            setTote('')
        } else if (tote === '') {
            toast({
                message: 'Empty Tote ID',
                type: 'error',
                timeout: TOAST_TIMEOUT_5_SEC
            })
        }
    },[toast, tote, totes])

    const totePressed = ({ key }) => {
        if (key === 'Enter') {
            toteClick()
        }
    }
    console.log(totes)

    useEffect(() => {
        counter > 0 && setTimeout(() => {
            setCounter(counter - 1)
        }, 1000)
        if(counter === 0){
            setTotes([])
            setScan({})
        }
    }, [counter])

    axios.defaults.headers.common['Authorization'] = useAuth().idToken;
    // Scan Submit
    const scanSubmit = useCallback(() => {
        setIsLoading(true)
        axiosPost('destination_scan',{
            warehouse_id: whid,
            // test printer ip
            printer_ip: '10.67.216.107',
            queue_name: queueName,
            // test badge id
            badge_id: '14619683',
            totes: totes
        })
            .then((scans: any) => {
                setScan(scans)
                setIsLoading(false)
                setCounter(10)
                console.log(scans)
            }).catch(err => {
                console.error(err)
                console.log(err)
            })
    }, [whid, queueName, totes])
    console.log(scan)

    const landingBackground = {
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        width: '100%',
        height: '100%',
    }

    const toteStyle = {
        height: '60px',
        padding: '5px'
    }
    
    return (
        <Theme tokens={orangeDarkTokens}>
            <div className='scanPage' style={landingBackground}>
                <BackButton />
                <Text alignment="center" type="d200" color="inverted">{queueName}</Text>
                <Box className='scanBox'>
                    <img src={toteimg} alt='tote' style={toteStyle} />
                    <input
                        style={{width: '300px', height: '30px', marginRight: '5px'}}
                        autoFocus
                        type='text'
                        placeholder="Scan/Enter Tote Id"
                        value={tote}
                        onChange={(e) => toteChange(e)}
                        onKeyDown={totePressed}
                    >

                    </input>
                    <Button onClick={() => toteClick()}>Add</Button>
                </Box>
                <Row width='100%' widths={['60%', '40%']}>
                    <Card>
                        <Text type='d50' alignment='center'>
                            Totes:
                        </Text>
                        <Divider/>
                        <ul>
                            {totes.map((tote, i) => (
                                <li key={i}>{tote}</li>
                            ))}
                        </ul>
                        <Button onClick={() => scanSubmit()}>Print</Button>
                    </Card>
                    <Card>
                        {counter > 0 ? <div style={{fontWeight: 'bold', color:'#FFA28A'}}>Auto Refresh in {counter} seconds</div> : <></>}
                        <Row widths='fill'>
                            <Text alignment='center' type='d50'>Bay</Text>
                            <Text alignment='center' type='d50'>Bin Type</Text>
                            <Text alignment='center' type='d50'>Totes</Text>
                        </Row>
                        <Divider/>
                        {isLoading ? (
                                <Column alignmentHorizontal='center' spacingInset='medium none'>
                                    <Loader type='circular' size='medium' />
                                </Column>
                            ) : (
                                Object.entries(scan).map(([id, label]) => (
                                    <Row widths='fill' key={id}>
                                        <Text alignment='center' type='b200'>{id}</Text>
                                        <Text alignment='center' type='b200'>{Object.values(label.bin_type)}</Text>
                                        <Text alignment='center' type='b200'>{Object.values(label.totes).join(', ')}</Text>
                                    </Row>
                                ))
                            )
                        }
                    </Card>
                </Row>
            </div>
        </Theme>
    );
}

export default Scans;
