import React, { useState, useRef } from 'react';
import '../../stylesheets/Space.css';


const PriorityOrder = () => {
    const dragItem = useRef(null);
    const dragOverItem = useRef();
    const [list, setList] = useState(['Restrictions', 'Disposition', 'GL', 'Size', 'Velocity']);

    const dragStart = (e, position) => {
        dragItem.current = position;
    };

    const dragEnter = (e, position) => {
        dragOverItem.current = position;
    }

    const drop = (e) => {
        const copyListItems = [...list];
        const dragItemContent = copyListItems[dragItem.current];
        copyListItems.splice(dragItem.current, 1);
        copyListItems.splice(dragOverItem.current, 0, dragItemContent);
        dragItem.current = null;
        dragOverItem.current = null;
        setList(copyListItems);
    }

    return (
        <>
        <div style={{color:'white', textAlign:'center', fontSize:'40px'}}>Drag item to ReArrange Priority Order</div>
        {
        list&&
        list.map((item, index) => (
            <div style={{backgroundColor:'black', color:'#FFFFFF', margin:'20px 25%', textAlign:'center', fontSize:'30px', cursor:'move'}}
                onDragStart={(e) => dragStart(e, index)}
                onDragEnter={(e) => dragEnter(e, index)}
                onDragEnd={drop}
                key={index}
                draggable>
                    {item}
            </div>
            ))}
        </>
    );

};

export default PriorityOrder;