import React, { useState, useCallback, useEffect } from "react"
import { useParams } from 'react-router-dom';
import Table, {
  TableRow,
  TableCell,
  TableActionBar,
  TableActionBarOverlay,
} from "@amzn/meridian/table"
import Button from "@amzn/meridian/button"
import Loader from "@amzn/meridian/loader";
import Column from "@amzn/meridian/column";
import Icon from "@amzn/meridian/icon"
import Input from "@amzn/meridian/input"
import cogTokens from '@amzn/meridian-tokens/base/icon/cog';
import pencilTokens from '@amzn/meridian-tokens/base/icon/pencil';
import downloadLargeTokens from '@amzn/meridian-tokens/base/icon/download-large';
import Text from '@amzn/meridian/text';
import Select, { SelectOption } from '@amzn/meridian/select';
import { axiosGet } from '../utils/axiosClient';
import { useAuth } from '../auth/AuthContext';
import axios from 'axios';
import BackButton from '../components/layout/BackButton';

const setAllKeys = (object, value) => {
    for (let key in object) object[key] = value
    return object
}

const Compliance = () => {
    const { whid } = useParams();

    const [isLoading, setIsLoading] = useState(false);
    const [selected, setSelected] = useState({
      one: false,
      two: false,
      three: false,
      four: false,
    })
    const [inputValue, setInputValue] = useState("")
    const [value, setValue] = useState()
    const [compliance, setCompliance] = useState([])

    const checkedCount = Object.values(selected).filter(Boolean).length
    
    const onCloseActionBar = useCallback(() => {
        const nextSelected = { ...setAllKeys(selected, false) }
        setSelected(nextSelected)
    }, [selected])

    axios.defaults.headers.common['Authorization'] = useAuth().idToken;
    useEffect(() => {
        setIsLoading(true)
        axiosGet('get_all_compliance', {warehouse_id: whid, status: 'all'})
            .then((compliance: any) => {
                setIsLoading(false)
                setCompliance(compliance)
            }).catch(err => {
                console.error(err)
            })    
    }, [whid])

    const landingBackground = {
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        width: '100%',
        height: '100%',
    }

    return (
        <div style={landingBackground}>
        <BackButton />
        <Table headerRows={1} showDividers={true}>
            <Text type="d200" alignment="center">Compliance</Text>
            <TableActionBar widths={[300, "fill"]}>
                <Input
                    aria-label="Label"
                    type="text"
                    size="medium"
                    placeholder="Filter by login..."
                    value={inputValue}
                    onChange={setInputValue}
                />
                <div />
                <Select
                    value={value}
                    onChange={setValue}
                    placeholder="All"
                    width={300}
                >
                    <SelectOption value="all" label="All" />
                    <SelectOption value="pending" label="Pending" />
                    <SelectOption value="compliant" label="Compliant" />
                    <SelectOption value="non-compliant" label="Non-Compliant" />
                </Select>
                <div />
                <Button type="icon">
                    <Icon tokens={downloadLargeTokens}>Download</Icon>
                </Button>
                <Button type="icon">
                    <Icon tokens={pencilTokens}>Edit</Icon>
                </Button>
                <Button type="icon">
                    <Icon tokens={cogTokens}>Settings</Icon>
                </Button>
                <Button type="primary">Search</Button>
                {checkedCount > 0 ? (
                    <TableActionBarOverlay
                        onClose={onCloseActionBar}
                        label={
                            <span>
                                <strong>{checkedCount}</strong>{" "}
                                {`item${checkedCount === 1 ? "" : "s"} `}
                                selected
                            </span>
                        }
                        closeLabel="Cancel"
                    >
                        <Button type="link">Bulk action</Button>
                        <Button type="link">Bulk action</Button>
                        <Button type="link">Bulk action</Button>
                    </TableActionBarOverlay>
                ) : null}
            </TableActionBar>
            <TableRow>
                <TableCell>Warehouse</TableCell>
                <TableCell>Login (alias)</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Tote</TableCell>
                <TableCell>Destination</TableCell>
            </TableRow>

            {isLoading ? (
                    <Column alignmentHorizontal='center' spacingInset='medium none'>
                        <Loader type='circular' size='medium' />
                    </Column>
                ) : (
                    compliance.map((t, i) => (
                        <TableRow key={i}>
                            <TableCell>{t.warehouse_id}</TableCell>
                            <TableCell>{t.login}</TableCell>
                            <TableCell>{t.status}</TableCell>
                            <TableCell>{t.tote}</TableCell>
                            <TableCell>{t.destination}</TableCell>
                    </TableRow>
                    ))
                )
            }
            
        </Table>
        </div>
    )
}

export default Compliance;
