import Alert from '@amzn/meridian/alert';
import { AlertType } from '@amzn/meridian/alert/alert';
import Toaster, { ToasterToasts } from '@amzn/meridian/toaster/toaster';
import React, { ReactNode, useCallback, useContext, createContext, useState, FC } from 'react';

export const TOAST_TIMEOUT_5_SEC = 5 * 1000;
export const TOAST_TIMEOUT_10_SEC = 10 * 1000;
export const TOAST_TIMEOUT_1_DAY = 864000 * 1000;

interface ToastInput {
  message: string | React.ReactElement;
  type?: AlertType;
  timeout?: number;
}

type Toast = ToastInput & ToasterToasts;

type ToastContext = (input: ToastInput) => void;

const ToastProviderContext = createContext<ToastContext>(() => {
  /* do nothing */
});

let currentId = 0;
const toastLimit = 5;
const generateId = (): string => {
  return `${currentId++}`;
};

const useToaster = (): ToastContext => {
  return useContext(ToastProviderContext);
};

interface ToastToasterProps {
  toasts: ToasterToasts[];
  onCloseToast: (id: string) => void;
}
const ToastToaster: FC<ToastToasterProps> = (
  props: ToastToasterProps
) => {
  return (
    <Toaster toasts={props.toasts} onCloseToast={props.onCloseToast}>
      {(toast) => (
        <Alert toast={true} onClose={toast.onClose} type={toast.type}>
          {toast.message}
        </Alert>
      )}
    </Toaster>
  );
};

const ToastProvider: FC<{
  children: ReactNode;
}> = (props: { children: ReactNode }) => {
  const [toasts, setToasts] = useState<Toast[]>([]);

  const toast = useCallback(
    ({ message, type, timeout = TOAST_TIMEOUT_10_SEC }: ToastInput): void => {
      setToasts((toasts: Toast[]) =>
        toasts.length < toastLimit
          ? [...toasts, { id: generateId(), message, type, timeout }]
          : [...toasts]
      );
    },
    []
  );
  const onCloseToast = useCallback((id: string): void => {
    setToasts((toasts) => toasts.filter((t) => t.id !== id));
  }, []);

  return (
    <ToastProviderContext.Provider value={toast}>
      <ToastToaster toasts={toasts} onCloseToast={onCloseToast} />
      {props.children}
    </ToastProviderContext.Provider>
  );
};

export { ToastProvider, useToaster };
