import React from 'react'
import Loader from '@amzn/meridian/loader';
import Column from '@amzn/meridian/column';
import Text from '@amzn/meridian/text';

export const LoadingSpinner = () => {
    return (
        <Column alignmentHorizontal='center' spacingInset='xlarge none'>
            <Loader type='circular' size='large' />
            <Text>Checking authorization...</Text>
        </Column>
    )
}
