import { CognitoConfig } from '../common/Interfaces';
import { Stage } from '../common/Constants';

const getStage = (): Stage => {
    const { hostname } = window.location;
    return hostname as Stage;
}
// TODO: Store Cognito info in CloudFront
const cognitoConfig: { [key: string]: CognitoConfig } = {
    [Stage.BETA]: {
        region: 'us-east-1',
        userPoolId: 'us-east-1_ji5P2KT2E',
        userPoolWebClientId: '68akp2ov3vb6goh1s7nes5lktb',
        oauth: {
            domain: 'space-beta.auth.us-east-1.amazoncognito.com',
            redirectSignIn: `${window.location.origin}/`,
            redirectSignOut: `${window.location.origin}/`,
            scope: ['openid', 'aws.cognito.signin.user.admin'],
            responseType: 'code'
        }
    },
    [Stage.PROD]: {
        region: 'us-east-1',
        userPoolId: 'us-east-1_EUatVAoOc',
        userPoolWebClientId: '365g6oel74kc6hk75tfk152om',
        oauth: {
            domain: 'space-prod.auth.us-east-1.amazoncognito.com',
            redirectSignIn: `${window.location.origin}/`,
            redirectSignOut: `${window.location.origin}/`,
            scope: ['openid', 'aws.cognito.signin.user.admin'],
            responseType: 'code'
        }
    }
}

export const getCognitoConfig = (): CognitoConfig => cognitoConfig[getStage()];
