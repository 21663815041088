import React, { useState, useEffect, createContext, useCallback } from 'react';
import { Link } from 'react-router-dom';
import Select, { SelectOption } from '@amzn/meridian/select';
import Loader from '@amzn/meridian/loader';
import Input from '@amzn/meridian/input';
import Row from '@amzn/meridian/row';
import Theme from '@amzn/meridian/theme';
import Button from '@amzn/meridian/button';
import Text from '@amzn/meridian/text';
import Column from '@amzn/meridian/column';
import escapeRegExp from 'lodash/escapeRegExp';
import { axiosGet } from '../utils/axiosClient';
import { useAuth } from '../auth/AuthContext';
import { FaRocket } from 'react-icons/fa';
import axios from 'axios';
import SpaceText from '../assets/SpaceTexts.png';
import orangeDarkTokens from '@amzn/meridian-tokens/theme/orange-dark';

export const WhidContext = createContext(undefined);

const LandingPage = () => {
    const [whid, setWhid] = useState()
    const [searchQuery, setSearchQuery] = useState()
    const [warehouses, setWarehouses] = useState([])
    const [onboard, setOnboard] = useState('')
    const [isLoading, setIsLoading] = useState(false)

    const onClickOpen = useCallback(() => console.log('Onboard Warehouse'), [])
    
    const searchRegExp = new RegExp(escapeRegExp(searchQuery), 'i')
    const matchedOptions = warehouses.filter(
        option => !searchQuery || searchRegExp.test(option.warehouse_id)
    )
    
    axios.defaults.headers.common['Authorization'] = useAuth().idToken;
    useEffect(() => {
        setIsLoading(true)
        axiosGet('get_all_fc_configuration', {})
            .then((allWarehouses: any) => {
                setWarehouses(allWarehouses)
                console.log(allWarehouses)
                setIsLoading(false)
            }).catch(err => {
                console.error(err)
            })
    }, [])

    const warehouseState = warehouses.find(({ warehouse_id }) => warehouse_id === whid)

    return (
            <WhidContext.Provider value={whid}>
                <div style={{ display: 'flex', justifyContent: 'center'}}>
                    <img src={SpaceText} alt='Space Logo Text' style={{width: '400px' }}></img>
                </div>
                <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '4%' }}>
                    <Select
                        value={whid}
                        onChange={setWhid}
                        searchQuery={searchQuery}
                        onSearch={setSearchQuery}
                        placeholder='Enter Warehouse ID...'
                        width={300}
                        size='medium'
                    >
                        {isLoading ? (
                            <Column alignmentHorizontal='center' spacingInset='medium none'>
                                <Loader type='circular' size='medium' />
                            </Column>
                        ) : (
                                matchedOptions.map(option => (
                                    <SelectOption 
                                        label={option.warehouse_id}
                                        value={option.warehouse_id}
                                        key={option.warehouse_id}
                                    />
                                ))
                            )
                        }
                        {!matchedOptions.length ? (
                            <Column alignmentVertical='center' spacing='300' spacingInset={"600"}>
                                <Text alignment='center'>Warehouse not listed?</Text>
                                <Input 
                                    id='onboard'
                                    value={onboard}
                                    onChange={setOnboard}
                                    type='text'
                                    placeholder='Enter Warehouse ID...'
                                />
                                <Button onClick={onClickOpen}>
                                   Onboard Warehouse
                                </Button>
                            </Column>
                        ) : null}
                    </Select>
                </div>
                <Theme tokens={orangeDarkTokens}>
                <Row alignmentHorizontal='center' spacingInset={'400'}>
                    <Button type='primary' size='medium'>
                        <Link 
                            style={{textDecoration: 'none', color: '#232F3E'}} 
                            to={whid}
                            state={{ data: warehouseState}}
                        >
                            Launch
                        </Link>
                        <FaRocket />
                    </Button>
                </Row>
                </Theme>
            </WhidContext.Provider>
    )
}

export default LandingPage;
